import { graphql } from 'gatsby';

import { StructuredText } from 'react-datocms';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { HeadingMedium, Title } from 'components/UI/Headings';
import Image from 'components/Image';
import {
  MainContainer,
  CardsContainer,
  HorizontalButtonContainer,
} from 'components/UI/Containers';
import {
  LightButton,
  PrimaryButton,
  TransparentButton,
} from 'components/UI/Buttons';

import Icon from 'assets/icons/play.svg';
import Arrow from 'assets/curly-arrow.svg';

import Card from 'components/Card';

import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from 'styles/elements';

import {
  Content,
  Description,
  Button,
  ScheduleContainer,
  Schedule,
  Table,
  Scrollable,
  Days,
  Class,
  Teacher,
  Course,
  Information,
} from './styles';

const ScheduleTemplate = ({ data, pageContext }) => {
  const {
    datoCmsStudentsPage: studentsPageData,
    datoCmsSchedulePage: schedulePageData,
  } = data;
  const { classText } = studentsPageData;
  const {
    title,
    description,
    videoButtonText,
    videoButtonLink,
    dayHoursTitle,
    dayHours,
    distributionTitle,
    distribution,
    infoTitle,
    infoDescription,
    infoRegistrationButtonText,
    infoRegistrationButtonLink,
    infoTelegramButtonText,
    infoTelegramButtonLink,
    infoPicture,
    infoBlocks,
  } = schedulePageData;

  return (
    <PageWrapper pageData={pageContext}>
      <MainContainer>
        <section>
          <Content>
            <div>
              <ShowOnlyOnDesktop>
                <HeadingMedium>{title}</HeadingMedium>
              </ShowOnlyOnDesktop>
              <Description>{description}</Description>
              <Button>
                <PrimaryButton
                  href={videoButtonLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  as="a"
                >
                  <Icon />
                  {videoButtonText}
                </PrimaryButton>
                <ShowOnlyOnDesktop>
                  <Arrow />
                </ShowOnlyOnDesktop>
              </Button>
            </div>
            <ScheduleContainer>
              <ShowOnlyOnMobile>
                <HeadingMedium>{title}</HeadingMedium>
              </ShowOnlyOnMobile>
              <Schedule>
                <Title>{dayHoursTitle}</Title>
                <Days>
                  {dayHours.map(({ day, hours }, index) => (
                    <div key={index}>
                      <p>{day}</p>
                      <span>{hours}</span>
                    </div>
                  ))}
                </Days>
              </Schedule>
              <Table>
                <Title>{distributionTitle}</Title>
                <Scrollable>
                  <Class>
                    <p />
                    {distribution.map(({ classNumber }, index) => {
                      return <p key={index}>{`${classNumber} ${classText}`}</p>;
                    })}
                  </Class>
                  <Teacher>
                    <p>Вчитель</p>
                    {distribution.map(({ teacher: { fullName } }, index) => {
                      return <p key={index}>{fullName}</p>;
                    })}
                  </Teacher>
                  <Course>
                    <p>Тема</p>
                    {distribution.map(({ course: { courseName } }, index) => {
                      return <p key={index}>{courseName}</p>;
                    })}
                  </Course>
                </Scrollable>
              </Table>
            </ScheduleContainer>
          </Content>
          <Information>
            <div>
              <ShowOnlyOnDesktop>
                <HeadingMedium>{infoTitle}</HeadingMedium>
              </ShowOnlyOnDesktop>
              <Description>
                <StructuredText data={infoDescription} />
              </Description>
              <HorizontalButtonContainer>
                <LightButton
                  href={infoRegistrationButtonLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  as="a"
                >
                  {infoRegistrationButtonText}
                </LightButton>
                <TransparentButton
                  href={infoTelegramButtonLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  as="a"
                >
                  {infoTelegramButtonText}
                </TransparentButton>
              </HorizontalButtonContainer>
            </div>
            <div>
              <ShowOnlyOnMobile>
                <HeadingMedium isCenter>{infoTitle}</HeadingMedium>
              </ShowOnlyOnMobile>
              <Image image={infoPicture} />
            </div>
          </Information>
          <CardsContainer>
            {infoBlocks.map(({ infoBlockTitle, infoBlockDetails }, index) => (
              <Card
                key={index}
                title={infoBlockTitle}
                description={infoBlockDetails}
              />
            ))}
          </CardsContainer>
        </section>
      </MainContainer>
    </PageWrapper>
  );
};

export default ScheduleTemplate;

export const query = graphql`
  query ScheduleQuery($locale: String!) {
    datoCmsSchedulePage(locale: { eq: $locale }) {
      locale
      title
      description
      videoButtonText
      videoButtonLink
      dayHoursTitle
      dayHours {
        day
        hours
      }
      distributionTitle
      distribution {
        classNumber
        teacher {
          fullName
        }
        course {
          courseName
        }
      }
      infoTitle
      infoDescription {
        value
      }
      infoRegistrationButtonText
      infoRegistrationButtonLink
      infoTelegramButtonText
      infoTelegramButtonLink
      infoPicture {
        gatsbyImageData
      }
      infoBlocks {
        infoBlockTitle
        infoBlockDetails {
          value
        }
      }
    }
    datoCmsStudentsPage(locale: { eq: $locale }) {
      classText
    }
  }
`;
