import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  align-items: ${(props) =>
    (props.align === 'center' && 'center') ||
    (props.align === 'right' && 'flex-end')};
  text-align: ${(props) => props.align};
  background-color: var(--card-background-color);
  grid-template-rows: auto auto 1fr;
  padding: 1.5rem 2rem 2.6875rem;
  display: grid;
`;

const Icon = styled.div`
  background-color: var(--color-dark-04);
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  border-radius: 50%;
  display: flex;
  height: 4rem;
  width: 4rem;

  svg {
    max-width: 2rem;
  }
`;

const Description = styled.div`
  line-height: 1.5rem;
  margin: 1rem 0 auto;

  ${media.mobileM`
    font-size: 1rem;
  `}

  a {
    font-weight: var(--weight-semi-bold);
    text-decoration: underline;

    &:hover {
      color: var(--secondary-color);
    }
  }
`;

export { Wrapper, Icon, Description };
