import styled, { css } from 'styled-components';

import { HeadingMedium } from 'components/UI/Headings';

import { media } from 'styles/mixins';

const Content = styled.div`
  grid-template-columns: 2fr 3fr;
  align-items: flex-start;
  column-gap: 4.5rem;
  display: grid;

  ${media.tabletS`
    grid-template-columns: auto;
  `}

  ${media.mobileM`
    display: flex;
    flex-direction: column-reverse;
  `}
`;

const Description = styled.div`
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 1rem 0 2rem;

  b,
  a,
  strong {
    font-weight: var(--weight-bold);
    text-decoration: underline;
  }

  ul,
  ol {
    list-style-type: auto;
    padding-left: 1rem;
  }
`;

const Button = styled.div`
  grid-template-columns: auto 1fr;
  column-gap: 1.8125rem;
  align-items: center;
  display: grid;

  ${media.mobileM`
    grid-template-columns: auto;
  `}
`;

const ScheduleContainer = styled.div`
  row-gap: 2.125rem;
  display: grid;

  ${media.mobileM`
    row-gap: 1.5rem;
  `}

  ${HeadingMedium} {
    padding-bottom: 0;
  }
`;

const Schedule = styled.div`
  background-color: var(--card-background-color);
  width: calc(100% + var(--container-padding));
  padding: 2rem 2.5rem;

  ${media.laptop`
    width: calc(100% + var(--container-padding-laptop));
  `}

  ${media.laptopS`
    width: calc(100% + var(--container-padding-laptop-small));
  `}

  ${media.tabletS`
    width: auto;
  `}

  ${media.mobileM`
    overflow: hidden;
    padding: 1rem;
  `}
`;

const Table = styled(Schedule)`
  ${media.mobileM`
    margin: 0 -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`;

const Scrollable = styled.div`
  ${media.mobileM`
    scrollbar-width: none;
    overflow-x: auto;
    margin: 0 -1rem;
    padding: 0 1rem;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const Days = styled.div`
  grid-auto-flow: column;
  column-gap: 5.625rem;
  width: fit-content;
  display: grid;

  ${media.mobileM`
    column-gap: 2.5rem;
  `}

  > div {
    border-left: 0.1875rem solid var(--primary-color);
    padding-left: 0.25rem;
  }

  p {
    line-height: 1.375rem;
  }

  span {
    font-size: 1.375rem;
    line-height: 1.875rem;
    font-weight: var(--weight-bold);
  }
`;

const ScheduleRowStyles = css`
  border-bottom: var(--border-width) solid var(--color-dark-03);
  grid-template-columns: repeat(5, 1fr);
  padding: 1.875rem 0;
  display: grid;

  ${media.mobileM`
    column-gap: 2rem;
    width: fit-content;
  `}

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 7.8125rem;
    overflow: hidden;

    ${media.laptop`
      max-width: 5rem;
    `}

    ${media.mobileM`
      white-space: unset;
      min-width: 7rem;
      max-width: 100%;
    `}
  }
`;

const Class = styled.div`
  ${ScheduleRowStyles}
  border-top: var(--border-width) solid var(--color-dark-03);
  font-weight: var(--weight-semi-bold);
  font-size: 0.875rem;
  opacity: 0.8;
`;

const Teacher = styled.div`
  ${ScheduleRowStyles}
  font-weight: var(--weight-semi-bold);
`;

const Course = styled.div`
  ${ScheduleRowStyles}
  color: var(--primary-color);
`;

const Information = styled.div`
  grid-template-columns: 5fr 4fr;
  margin: 11.875rem 0 8.875rem;
  column-gap: 5rem;
  display: grid;

  ${media.mobileM`
    display: flex;
    margin: 2rem 0;
    flex-direction: column-reverse;
  `}
`;

export {
  Content,
  Description,
  Button,
  ScheduleContainer,
  Schedule,
  Table,
  Scrollable,
  Days,
  Class,
  Teacher,
  Course,
  Information,
};
