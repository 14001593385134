import styled from 'styled-components';

import { media } from 'styles/mixins';
import { easeOutTiming } from 'styles/animation';

const Button = styled.button`
  transition: all ${easeOutTiming};
  border: var(--border-width) solid transparent;
  font-weight: var(--weight-semi-bold);
  padding: 1.0625rem 2rem;
  grid-auto-flow: column;
  line-height: 1.125rem;
  font-size: 1.125rem;
  align-items: center;
  column-gap: 0.75rem;
  display: grid;

  svg path {
    transition: all ${easeOutTiming};
  }
`;

const PrimaryButton = styled(Button)`
  border-color: var(--primary-button-border-color);
  background-color: var(--primary-button-background);
  color: var(--primary-button-color);

  &:hover {
    color: var(--primary-button-background);
    border-color: var(--primary-button-color);
    background-color: var(--primary-button-color);

    svg path {
      stroke: var(--primary-button-background);
    }
  }
`;

const LightButton = styled(Button)`
  border-color: var(--light-button-border-color);
  background-color: var(--light-button-background);
  color: var(--light-button-color);

  &:hover {
    border-color: var(--light-button-background);
    background-color: var(--light-button-color);
    color: var(--light-button-background);
  }
`;

const DarkButton = styled(Button)`
  border-color: var(--dark-button-border-color);
  background-color: var(--dark-button-background);
  color: var(--dark-button-color);

  &:hover {
    background-color: var(--primary-color);
  }
`;

const TransparentButton = styled(Button)`
  border-color: var(--transparent-button-border-color);
  background-color: var(--transparent-button-background);
  color: var(--transparent-button-color);

  &:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
`;

const PlayButtonWrapper = styled.button`
  justify-content: center;
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  position: relative;
  width: 6.875rem;
  height: 6.875rem;
  overflow: hidden;

  ${media.mobileM`
    height: 4.5rem;
    width: 4.5rem;
  `}

  &::before {
    content: '';
    background-color: var(--play-button-first-background-color);
    position: absolute;
    opacity: 0.6;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
`;

const PlayIcon = styled.span`
  background-color: var(--play-button-second-background-color);
  position: relative;
  border-radius: 50%;
  height: 4.125rem;
  width: 4.125rem;
  display: block;

  ${media.mobileM`
    width: 2.5rem;
    height: 2.5rem;
  `}

  &::before {
    width: 0;
    height: 0;
    content: '';
    top: 1.0625rem;
    left: 1.375rem;
    position: absolute;
    border-style: solid;
    border-width: 1rem 0 1rem 1.8rem;
    border-color: transparent transparent transparent
      var(--play-button-icon-color);

    ${media.mobileM`
      border-width: 0.5rem 0 0.5rem 0.9rem;
      left: 1rem;
      top: 0.75rem;
    `}
  }
`;

const PlayButton = ({ onClick }) => {
  return (
    <PlayButtonWrapper onClick={onClick}>
      <PlayIcon />
    </PlayButtonWrapper>
  );
};

export {
  PrimaryButton,
  LightButton,
  DarkButton,
  TransparentButton,
  PlayButton,
};
