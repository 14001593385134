import PropTypes from 'prop-types';
import { StructuredText } from 'react-datocms';

import { Title } from 'components/UI/Headings';

import { Wrapper, Icon, Description } from './styles';

const Card = ({ icon, title, description, align }) => {
  return (
    <Wrapper align={align}>
      {icon && <Icon>{icon}</Icon>}
      <Title isDivider>{title}</Title>
      <Description>
        {typeof description === 'object' ? (
          <StructuredText data={description} />
        ) : (
          <p>{description}</p>
        )}
      </Description>
    </Wrapper>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

Card.defaultProps = {
  align: 'left',
};

export default Card;
